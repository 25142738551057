.capture-selfie {
  text-align: left;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  .form-group {
    margin-bottom: 2rem;
  }

  .form-text {
    margin-top: 1rem;
  }

  .img-thumbnail {
    max-width: 100%;
    max-height: 250px;
  }
}