.landing-page {
  text-align: left;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  font-size: 14px;
  &__title {
    font-size: x-large;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
  }
  &__subtitle {
    font-size: medium;
  }
  &__message {
    margin-top: 10px;
    color: red;
    font-weight: 500;
  }
}
.button-back {
  color: red;
}
