.image-verification {
  width: 100%;
}

.special-div {
  position: absolute;
  width: 100%;
  &.hidden {
    display: none;
  }
}