body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  margin-bottom: 1rem;
}

.col-sm-4 {
  border-left: 1px solid #ccc;
  &:first-child {
    border: none;
  }
}

@media (max-width: 576px) {
  .col-sm-4 {
    border-left: none;
    border-top: 1px solid #ccc;
    &:first-child {
      border: none;
    }
  }
}
