.validate {
  text-align: left;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  &__title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}