.capture-document {
  text-align: left;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  .form-group {
    margin-bottom: 2rem;
  }

  .form-text {
    margin-top: 1rem;
  }

  .img-thumbnail {
    max-width: 100%;
    max-height: 250px;
  }
  .step1 {
    font-weight: bold;
  }
}
